import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {tracked} from '@glimmer/tracking';
import {computed} from '@ember/object';

const Validations = buildValidations({
  wagering_percent: {
    description: 'Wagering Percent',
    validators: [
      validator('number', {
        gt: 0, lte: 100,
        message: computed('model.wagering_percent', function () {
          if (this.model.wagering_percent > 100) {
            return 'Must be less than 100';
          }
          return 'Must be greater than 0';
        })
      })
    ],
  }
});

export default class CasinoTemplateGameListModel extends Model.extend(Validations) {
  @attr background
  @attr casino_game_list_id
  @attr charged
  @attr desktop
  @attr rank
  @attr position
  @attr free
  @attr('boolean') is_free_spins
  @attr game_code
  @attr('string') provider_name
  @attr('image') image_url
  @attr('boolean') is_active
  @attr mobile
  @attr name
  @attr('order', {defaultValue: 999}) order
  @attr template_category_ids
  @attr template_sub_category_ids
  @attr template_type_lable_ids
  @attr('number') wagering_percent;
  @attr template_id
  @attr integration_id
  @attr template_provider_id
  @attr url
  @attr('boolean') is_vpn

  @belongsTo('casino-integration', {async: false, inverse: null}) integration
  @belongsTo('casino-template-category', {async: false, inverse: null}) templateCategory
  @hasMany('casino-template-category', {async: false, inverse: null}) templateCategories
  @belongsTo('casino-template-sub-category', {async: false, inverse: null}) templateSubCategory
  @hasMany('casino-template-sub-category', {async: false, inverse: null}) templateSubCategories
  @hasMany('country', {async: false, inverse: null}) countries

  @tracked checked = false
  @tracked isLastSelected = false

  get templateCategoriesCommaSeperated() {
    const categories = this.templateCategories.reduce((array, category) => {
      return [...array, category.title ];
    }, []);

    return categories.join(',')
  }

  get templateSubCategoriesCommaSeperated() {
    const categories = this.templateSubCategories.reduce((array, category) => {
      return [...array, category.title ];
    }, []);

    return categories.join(',')
  }

  modelName = 'casino-template-game-list'
}
